import { // ref,
reactive, onMounted, computed } from 'vue';
import backgroundImg from '@/assets/images/footer-background.png';
import { useStore } from 'vuex';
export default {
  name: 'Footer',
  props: {
    msg: String
  },

  setup() {
    const store = useStore();
    const imgBackground = reactive(backgroundImg);
    const info = computed(() => store.getters.companyInfo);
    onMounted(() => {});
    return {
      info,
      imgBackground
    };
  }

};