import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Layout from '@/views/layout/main.vue'
// import store from "@/store";

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/',
			name: 'Layout',
			component: Layout,
			meta: {
				auth: false
			},
			children: []
		},
		{
			path: '/:catchAll(.*)',
			hidden: true,
			component: () => import('@/views/error/404.vue')
		},
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/views/login/login.vue')
		},
		{
			path: '/about',
			name: 'About',
			component: () => import('@/views/test/about.vue')
		}
	]
});
//需要展示底部导航的页面
const routeList = [
	{
		path: '/index',
		name: 'Index',
		url: 'views/index/index.vue',
		auth: false,
		title: '首页'
	},
	{
		path: '/consultCenter',
		name: 'consultCenter',
		url: 'views/consultCenter/index.vue',
		auth: false,
		title: '咨询中心'
	},
	{
		path: '/consultCenter/consultGuide',
		name: 'consultCenter.consultGuide',
		url: 'views/consultCenter/consultGuide.vue',
		auth: false,
		title: '咨询指南'
	},
	{
		path: '/consultCenter/newsList',
		name: 'consultCenter.newsList',
		url: 'views/consultCenter/newsList.vue',
		auth: false,
		title: '新闻列表'
	},
	{
		path: '/consultCenter/authGuide',
		name: 'consultCenter.authGuide',
		url: 'views/consultCenter/authGuide.vue',
		auth: false,
		title: '授权指南'
	},
	{
		path: '/consultCenter/authCenter',
		name: 'consultCenter.authCenter',
		url: 'views/consultCenter/authCenter.vue',
		auth: false,
		title: '咨询中心'
	},
	{
		path: '/consultCenter/provinceCenter',
		name: 'consultCenter.provinceCenter',
		url: 'views/consultCenter/authCenter-province.vue',
		auth: false,
		title: '咨询中心'
	},
	{
		path: '/consultCenter/cityCenter',
		name: 'consultCenter.cityCenter',
		url: 'views/consultCenter/authCenter-city.vue',
		auth: false,
		title: '咨询中心'
	},
	{
		path: '/consultCenter/regionCenter',
		name: 'consultCenter.regionCenter',
		url: 'views/consultCenter/authCenter-region.vue',
		auth: false,
		title: '咨询中心'
	},
	{
		path: '/consultCenter/team',
		name: 'consultCenter.team',
		url: 'views/consultCenter/team.vue',
		auth: false,
		title: '专家团队'
	},
	{
		path: '/consultCenter/mentor',
		name: 'consultCenter.mentor',
		url: 'views/consultCenter/mentor.vue',
		auth: false,
		title: '咨询师介绍'
	},
	{
		path: '/consultCenter/person',
		name: 'consultCenter.person',
		url: 'views/consultCenter/person.vue',
		auth: false,
		title: '中心成员'
	},
	{
		path: '/lifeAwaken',
		name: 'lifeAwaken',
		url: 'views/lifeAwaken/index.vue',
		auth: false,
		title: '生命觉醒'
	},
	{
		path: '/lifeAwaken/introduction',
		name: 'lifeAwaken.introduction',
		url: 'views/lifeAwaken/introduction.vue',
		auth: false,
		title: '课程介绍'
	},
	{
		path: '/lifeAwaken/scheduling',
		name: 'lifeAwaken.scheduling',
		url: 'views/lifeAwaken/scheduling.vue',
		auth: false,
		title: '课程排期'
	},
	{
		path: '/contentCenter',
		name: 'contentCenter',
		url: 'views/contentCenter/index.vue',
		auth: false,
		title: '内容中心'
	},
	{
		path: '/newsCenter',
		name: 'newsCenter',
		url: 'views/newsCenter/index.vue',
		auth: false,
		title: '新闻中心'
	},
	{
		path: '/bodyCondition',
		name: 'bodyCondition',
		url: 'views/bodyCondition/index.vue',
		auth: false,
		title: '身体调理'
	},
	{
		path: '/bodyCondition/introduction',
		name: 'bodyCondition.introduction',
		url: 'views/bodyCondition/introduction.vue',
		auth: false,
		title: '课程介绍'
	},
	{
		path: '/bodyCondition/service',
		name: 'bodyCondition.service',
		url: 'views/bodyCondition/service.vue',
		auth: false,
		title: '环境禅雅及服务'
	},
	{
		path: '/heartExpo',
		name: 'heartExpo',
		url: 'views/heartExpo/index.vue',
		auth: false,
		title: '心博会'
	},
	{
		path: '/heartExpo/introduction',
		name: 'heartExpo.introduction',
		url: 'views/heartExpo/introduction.vue',
		auth: false,
		title: '课程介绍'
	},
	{
		path: '/heartExpo/summerCamp',
		name: 'heartExpo.summerCamp',
		url: 'views/heartExpo/summerCamp.vue',
		auth: false,
		title: '心博会夏令营'
	},
	{
		path: '/heartExpo/workshop',
		name: 'heartExpo.workshop',
		url: 'views/heartExpo/workshop.vue',
		auth: false,
		title: '心博会工作坊'
	},
	{
		path: '/heartExpo/scheduling',
		name: 'heartExpo.scheduling',
		url: 'views/heartExpo/scheduling.vue',
		auth: false,
		title: '课程排期'
	},
	{
		path: '/heartExpo/mentor',
		name: 'heartExpo.mentor',
		url: 'views/heartExpo/mentor.vue',
		auth: false,
		title: '导师介绍'
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		url: 'views/aboutUs/index.vue',
		auth: false,
		title: '公司简介'
	},

	{
		path: '/aboutUs/introduce',
		name: 'aboutUs/introduce',
		url: 'views/aboutUs/introduce.vue',
		auth: false,
		title: '公司简介'
	}
	,
	{
		path: '/aboutUs/develop',
		name: 'aboutUs.develop',
		url: 'views/aboutUs/develop.vue',
		auth: false,
		title: '公司发展'
	},

	{
		path: '/aboutUs/teamInfo',
		name: 'aboutUs.teamInfo',
		url: 'views/aboutUs/teamInfo.vue',
		auth: false,
		title: '管理团队'
	},

	{
		path: '/aboutUs/corporateCulture',
		name: 'aboutUs.corporateCulture',
		url: 'views/aboutUs/corporateCulture.vue',
		auth: false,
		title: '企业文化'
	},

	{
		path: '/aboutUs/organization',
		name: 'aboutUs.organization',
		url: 'views/aboutUs/organization.vue',
		auth: false,
		title: '组织架构'
	},

	{
		path: '/aboutUs/chairman',
		name: 'aboutUs.chairman',
		url: 'views/aboutUs/chairman.vue',
		auth: false,
		title: '董事长寄语'
	},
	{
		path: '/aboutUs/show',
		name: 'aboutUs.show',
		url: 'views/aboutUs/show.vue',
		auth: false,
		title: '风采展示'
	},
	{
		path: '/aboutUs/welfare',
		name: 'aboutUs.welfare',
		url: 'views/aboutUs/welfare.vue',
		auth: false,
		title: '社会公益'
	},
	{
		path: '/aboutUs/recruit',
		name: 'aboutUs.recruit',
		url: 'views/aboutUs/recruit.vue',
		auth: false,
		title: '人才招聘'
	},
	{
		path: '/aboutUs/message',
		name: 'aboutUs.message',
		url: 'views/aboutUs/message.vue',
		auth: false,
		title: '在线留言'
	},
	{
		path: '/lifeAwaken/life',
		name: 'lifeAwaken.life',
		url: 'views/life/index.vue',
		auth: false,
		title: '生命觉醒'
	},
	{
		path: '/lifeAwaken/xuesheng',
		name: 'lifeAwaken.xuesheng',
		url: 'views/xuesheng/index.vue',
		auth: false,
		title: '学生专场'
	},
	{
		path: '/lifeAwaken/jiazhang',
		name: 'lifeAwaken.jiazhang',
		url: 'views/jiazhang/index.vue',
		auth: false,
		title: '家长专场'
	},
	{
		path: '/lifeAwaken/danshen',
		name: 'lifeAwaken.danshen',
		url: 'views/danshen/index.vue',
		auth: false,
		title: '单身专场'
	},
	{
		path: '/lifeAwaken/laonian',
		name: 'lifeAwaken.laonian',
		url: 'views/laonian/index.vue',
		auth: false,
		title: '老年专场'
	},
	{
		path: '/lifeAwaken/qiyejia',
		name: 'lifeAwaken.qiyejia',
		url: 'views/qiyejia/index.vue',
		auth: false,
		title: '企业家专场'
	},
	{
		path: '/culture',
		name: 'culture',
		url: 'views/culture/index.vue',
		auth: false,
		title: '传统文化'
	},
]
router.beforeEach((to,from,next)=>{
	if (router.hasRoute(to.name)) {
		if (to.meta.auth) {
			if (sessionStorage.getItem("token")) {
				next();
			} else{
				next({name: 'Login'});
			}
		} else{
			if (to.name != from.name) {
				next();
			}
		}
	} else {
		let flag = false;
		routeList.forEach(item => {
			router.addRoute('Layout', {
				path: item.path,
				name: item.name,
				meta: {
					title: item.title,
					auth: item.auth
				},
				component: () => import(`../${item.url}`)
			});
			if (item.path == to.path) {
				flag = true;
			}
		})
		if (flag) {
			// store.dispatch('setPath', to.path)
			next({...to});
		} else{
			next({name: 'Layout'});
		}
	}
});
export default router;
