import {
	createStore
} from 'vuex'
import { getDictData, company } from '@/api/index.js'

const store = createStore({
	state: {
		path: '/',
		location: '',
		locationCity: '',
		contentTop:  [],
		contentSecond:  [],
		counselorLevel:  [],
		consultType:  [],
		companyInfo: {},
	},
	mutations: {
		SET_PATH(state, data) {
			state.path = data;
		},
		SET_LOCATION(state, data) {
			state.location = data;
		},
		SET_LOCATION_CITY(state, data) {
			state.locationCity = data;
		},
		SET_CONTENT_TOP(state, list) {
			state.contentTop = list;
		},
		SET_CONTENT_SECOND(state, list) {
			state.contentSecond = list;
		},
		SET_COUNSELOR_LEVEL(state, list) {
			state.counselorLevel = list;
		},
		SET_CONSULT_TYPE(state, list) {
			state.consultType = list;
		},
		SET_COMPANY_INFO(state, data) {
			state.companyInfo = data;
		},
	},
	actions: {
		setPath({commit} ,data) {
			commit('SET_PATH', data);
		},
		setLocation({commit} ,data) {
			commit('SET_LOCATION', data);
		},
		setLocationCity({commit} ,data) {
			commit('SET_LOCATION_CITY', data);
		},
		async getContentTop( {commit} ) {
			const res = await getDictData({code: 'content_top', pageNum: 1, pageSize: 1000});
			if (res.code === 200) {
				let list = res.data.map(item => {
					return {
						text: item.label,
						value: item.value
					}
				})
				commit('SET_CONTENT_TOP', list);
				return Promise.resolve();
			} else {
				return Promise.reject(res.msg);
			}
		},
		async getContentSecond( {commit} ) {
			const res = await getDictData({code: 'content_second', pageNum: 1, pageSize: 1000});
			if (res.code === 200) {
				let list = res.data.map(item => {
					return {
						text: item.label,
						value: item.value
					}
				})
				commit('SET_CONTENT_SECOND', list);
				return Promise.resolve();
			} else {
				return Promise.reject(res.msg);
			}
		},
		async getCounselorLevel( {commit} ) {
			const res = await getDictData({code: 'counselor_level', pageNum: 1, pageSize: 1000});
			if (res.code === 200) {
				let list = res.data.map(item => {
					return {
						text: item.label,
						value: item.value
					}
				})
				commit('SET_COUNSELOR_LEVEL', list);
				return Promise.resolve();
			} else {
				return Promise.reject(res.msg);
			}
		},
		async getConsultType( {commit} ) {
			const res = await getDictData({code: 'consultType', pageNum: 1, pageSize: 1000});
			if (res.code === 200) {
				let list = res.data.map(item => {
					return {
						text: item.label,
						value: item.value
					}
				})
				commit('SET_CONSULT_TYPE', list);
				return Promise.resolve();
			} else {
				return Promise.reject(res.msg);
			}
		},
		async getCompanyInfo( {commit} ) {
			const res = await company();
			if (res.code === 200) {
				let data = res.data;
				commit('SET_COMPANY_INFO', data);
				return Promise.resolve();
			} else {
				return Promise.reject(res.msg);
			}
		},
	},
	getters: {
		path: state => state.path,
		location: state => state.location,
		locationCity: state => state.locationCity,
		contentTop: state => state.contentTop,
		contentSecond: state => state.contentSecond,
		counselorLevel: state => state.counselorLevel,
		consultType: state => state.consultType,
		companyInfo: state => state.companyInfo,
	},
	modules: {}
})

export default store;
