import Header from '@/components/header';
import Footer from '@/components/footer';
import Detail from '@/components/detail';
import arrowUpImg from '@/assets/images/arrow-up.png';
import zeArrowLeftImg from '@/assets/images/ze-arrow-left.png';
import zeArrowRightImg from '@/assets/images/ze-arrow-right.png';
import commentImg from '@/assets/images/mb-comment@1x.png';
import { ref, reactive } from 'vue';
import router from '@/router';
export default {
  name: 'Layout',
  components: {
    Header,
    Footer
  },

  setup() {
    const viewRef = ref();
    const scrollbarRef = ref();
    const state = reactive({
      imgArrowUp: arrowUpImg,
      imgZeArrowLeft: zeArrowLeftImg,
      imgZeArrowRight: zeArrowRightImg,
      imgComment: commentImg,
      visible: false,
      contentDetail: {}
    });

    const setDetail = ({
      visible,
      contentDetail
    }) => {
      state.visible = visible;
      state.contentDetail = contentDetail;
    };

    const gotoMessage = () => {
      router.push({
        path: "/aboutUs/message"
      });
    };

    const gotoTop = () => {
      scrollbarRef.value.setScrollTop(0);
    };

    const handleScroll = v => {
      if (v.scrollTop > 500) {
        document.querySelector('.goTop').style.display = 'block';
      } else {
        document.querySelector('.goTop').style.display = 'none';
      }
    };

    return {
      viewRef,
      scrollbarRef,
      state,
      setDetail,
      gotoTop,
      gotoMessage,
      handleScroll
    };
  }

};