import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0c9b54f3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "detail-panel"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "detail-right"
};
const _hoisted_4 = {
  key: 0,
  class: "detail-title"
};
const _hoisted_5 = {
  key: 1,
  class: "detail-time",
  style: {
    "font-weight": "600"
  }
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 2,
  class: "detail-time",
  style: {
    "font-weight": "600"
  }
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  key: 3,
  class: "detail-time"
};
const _hoisted_10 = {
  key: 4,
  class: "detail-time"
};
const _hoisted_11 = {
  key: 5,
  class: "detail-remark"
};
const _hoisted_12 = ["innerHTML"];
const _hoisted_13 = {
  key: 6,
  class: "detail-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");

  const _component_el_carousel = _resolveComponent("el-carousel");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $props.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $props.visible = $event),
    width: "80%",
    "align-center": "",
    modal: "",
    "destroy-on-close": "",
    "show-close": true,
    onClose: $setup.close
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.contentDetail.showImages && $props.contentDetail.showImages.length > 0 ? (_openBlock(), _createBlock(_component_el_carousel, {
      key: 0,
      class: "detail-left"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.contentDetail.showImages, (item, index) => {
        return _openBlock(), _createBlock(_component_el_carousel_item, {
          key: index
        }, {
          default: _withCtx(() => [_createElementVNode("img", {
            src: item,
            style: {
              "width": "100%"
            }
          }, null, 8, _hoisted_2)]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_scrollbar, {
      ref: "scrollbarRef"
    }, {
      default: _withCtx(() => [$props.contentDetail.title ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.contentDetail.title), 1)) : _createCommentVNode("", true), $props.contentDetail.local ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
        src: $setup.state.imgHome,
        style: {
          "display": "inline-block",
          "width": "16px",
          "height": "16px",
          "margin-right": "10px"
        }
      }, null, 8, _hoisted_6), _createTextVNode(" " + _toDisplayString($props.contentDetail.local), 1)])) : _createCommentVNode("", true), $props.contentDetail.level ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
        src: $setup.state.imgUser,
        style: {
          "display": "inline-block",
          "width": "16px",
          "height": "16px",
          "margin-right": "10px"
        }
      }, null, 8, _hoisted_8), _createTextVNode(" " + _toDisplayString($props.contentDetail.level), 1)])) : _createCommentVNode("", true), $props.contentDetail.showTime ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($props.contentDetail.showTime), 1)) : $props.contentDetail.updateTime ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.contentDetail.updateTime), 1)) : _createCommentVNode("", true), $props.contentDetail.remark ? (_openBlock(), _createElementBlock("div", _hoisted_11, "作者：" + _toDisplayString($props.contentDetail.remark), 1)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "detail-content",
        innerHTML: $props.contentDetail.content,
        style: _normalizeStyle({
          'font-size': $props.contentDetail.isShowBigFont ? '16px' : '',
          'line-height': $props.contentDetail.isShowBigFont ? '24px' : ''
        })
      }, null, 12, _hoisted_12), $props.contentDetail.domain ? (_openBlock(), _createElementBlock("div", _hoisted_13, "咨询范围：" + _toDisplayString($props.contentDetail.domain), 1)) : _createCommentVNode("", true)]),
      _: 1
    }, 512)])])]),
    _: 1
  }, 8, ["modelValue", "onClose"]);
}