import { reactive, onMounted, ref } from 'vue';
import Menu from '@/components/menu';
import LogoImg from '@/assets/images/logo.jpg';
import GgImg from '@/assets/images/mb-comment@1x.png';
import { announce } from '@/api/index';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  components: {
    Menu
  },

  setup() {
    const imgLogo = reactive(LogoImg);
    const imgGG = reactive(GgImg);
    const visible = ref(false);
    const title = ref('');
    const content = ref('');

    const getAnnounce = () => {
      visible.value = !visible.value;

      if (visible.value) {
        announce().then(response => {
          if (response.code == 200) {
            title.value = response.data.title;
            content.value = response.data.content;
          }
        });
      }
    };

    onMounted(() => {// getAnnounce();
    });
    return {
      imgLogo,
      imgGG,
      visible,
      title,
      content,
      getAnnounce
    };
  }

};