import {
	createApp
} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import directives from './directives'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Varlet from '@varlet/ui'
import '@varlet/ui/es/style.mjs'
import '@/assets/css/style.css'
import 'animate.css';
import './mock'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(directives)
app.use(ElementPlus)
app.use(Varlet)
app.mount('#app')

router.afterEach(() => {
  const scrollContainer = document.querySelector('.el-scrollbar__wrap')
  if (scrollContainer) {
    scrollContainer.scrollTo({ top: 0 })
  }
});
