import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "common-layout"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_el_header = _resolveComponent("el-header");

  const _component_router_view = _resolveComponent("router-view");

  const _component_Footer = _resolveComponent("Footer");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  const _component_el_main = _resolveComponent("el-main");

  const _component_el_container = _resolveComponent("el-container");

  const _component_Detail = _resolveComponent("Detail");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_header, null, {
      default: _withCtx(() => [_createVNode(_component_Header)]),
      _: 1
    }), _createVNode(_component_el_main, null, {
      default: _withCtx(() => [_createVNode(_component_el_scrollbar, {
        ref: "scrollbarRef",
        onScroll: $setup.handleScroll
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view, {
          ref: "viewRef",
          onSetDetail: $setup.setDetail
        }, null, 8, ["onSetDetail"]), _createVNode(_component_Footer)]),
        _: 1
      }, 8, ["onScroll"])]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", {
    ref: "goMessage",
    class: "goMessage",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => $setup.gotoMessage(), ["stop"]))
  }, [_createElementVNode("img", {
    src: $setup.state.imgComment
  }, null, 8, _hoisted_2)], 512), _createElementVNode("div", {
    ref: "goTop",
    class: "goTop",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => $setup.gotoTop(), ["stop"]))
  }, [_createElementVNode("img", {
    src: $setup.state.imgArrowUp
  }, null, 8, _hoisted_3)], 512), _createVNode(_component_Detail, {
    visible: $setup.state.visible,
    onClose: _cache[2] || (_cache[2] = $event => $setup.state.visible = false),
    contentDetail: $setup.state.contentDetail
  }, null, 8, ["visible", "contentDetail"])]);
}