import axios from 'axios'
import router from '@/router'
import { Snackbar } from '@varlet/ui'

const instance = axios.create({
	// baseURL: 'http://127.0.0.1:9292',
	baseURL: 'https://bjht.bamaol.com/',
	timeout: 10000
})

instance.interceptors.request.use(function(config) {
	if (sessionStorage.getItem('token')) {
		config.headers.token = sessionStorage.getItem('token');
	}
	return config;
}, function(error) {
	return Promise.reject(error);
})

instance.interceptors.response.use(function(response) {
	if (response.status == 200) {
		if (response.config.responseType == 'blob') {
			return Promise.resolve(response.data);
		} else{
			if (response.data.code == 200) {
				return Promise.resolve(response.data);
			} else if (response.data.code == 401) {
				Snackbar.error('登录过期，请重新登录');
				router.replace({
					path: 'login'
				})
			} else {
				Snackbar.error(response.data.msg);
				return Promise.reject(response.data.msg);
			}
		}
	} else{
		Snackbar.error(response.statusText);
		return Promise.reject(response.statusText);
	}
}, function(error) {
	Snackbar.error('系统出错啦，请联系管理员~');
	return Promise.reject(error);
})

export default instance;
