import request from '@/utils/axiosInstance.js'

//查看数据字典
export function getDictData(data) {
	return request({
		url: '/portal/getDictData',
		method: 'get',
		params: data
	})
}
//查看所有省份
export function getProvince(data) {
	return request({
		url: '/portal/getProvince',
		method: 'get',
		params: data
	})
}
//查看下级城市
export function getCity(data) {
	return request({
		url: '/portal/getCity',
		method: 'get',
		params: data
	})
}
//咨询中心列表当前定位下或者选择的省市对应的区县，只传city或者location，别的参数忽略
export function getCountyList(data) {
	return request({
		url: '/portal/getCountyList',
		method: 'get',
		params: data
	})
}
//查看咨询中心列表，region为空就默认返回全是县市的
export function authList(data) {
	return request({
		url: '/portal/authList',
		method: 'get',
		params: data
	})
}
//根据资源查询banner
export function banner(data) {
	return request({
		url: '/portal/banner',
		method: 'get',
		params: data
	})
}
//查看公司基本信息
export function company(data) {
	return request({
		url: '/portal/company',
		method: 'get',
		params: data
	})
}
//查看内容
export function content(data) {
	return request({
		url: '/portal/content',
		method: 'get',
		params: data
	})
}
//查看公司新闻列表
export function getNews(data) {
	return request({
		url: '/portal/news',
		method: 'get',
		params: data
	})
}
//查看咨询中心咨询师列表
export function counselorList(data) {
	return request({
		url: '/portal/counselorList',
		method: 'get',
		params: data
	})
}
//查看发展历史
export function develop(data) {
	return request({
		url: '/portal/develop',
		method: 'get',
		params: data
	})
}

//查看发展历史
export function XinDevelop(data) {
	return request({
		url: '/portal/XinDevelop',
		method: 'get',
		params: data
	})
}


//查看咨询中心详情，只获取一页数量的新闻和咨询师
export function getAuthInfo(data) {
	return request({
		url: '/portal/getAuthInfo',
		method: 'get',
		params: data
	})
}
//查看咨询中心新闻
export function getAuthNews(data) {
	return request({
		url: '/portal/getAuthNews',
		method: 'get',
		params: data
	})
}
//查看导师列表,导师类型category：1心博会，2生命觉醒 3身体调理 4咨询中心 5学生专场 6家长专场 7单身专场 8老年专场 9企业家专场
export function mentor(data) {
	return request({
		url: '/portal/mentor',
		method: 'get',
		params: data
	})
}
//常见问题列表 category 1心理咨询指南 2咨询中心指南
export function question(data) {
	return request({
		url: '/portal/question',
		method: 'get',
		params: data
	})
}
//招聘列表
export function recruit(data) {
	return request({
		url: '/portal/recruit',
		method: 'get',
		params: data
	})
}
//查看公司风采展示
export function show(data) {
	return request({
		url: '/portal/show',
		method: 'get',
		params: data
	})
}
//查看课程列表,category:1心博会，2生命觉醒 3身体调理 4咨询中心 5学生专场 6家长专场 7单身专场 8老年专场 9企业家专场
export function subject(data) {
	return request({
		url: '/portal/subject',
		method: 'get',
		params: data
	})
}
//团队介绍
export function teamInfo(data) {
	return request({
		url: 'portal/teamInfo',
		method: 'get',
		params: data
	})
}
//公告列表
export function announce(data) {
	return request({
		url: 'portal/announce',
		method: 'get',
		params: data
	})
}


//申请课程报名
export function apply(data) {
	return request({
		url: '/portal/apply',
		method: 'post',
		data: data
	})
}
//添加在线留言
export function leaveWord(data) {
	return request({
		url: '/portal/leaveWord',
		method: 'post',
		data: data
	})
}
//手机号验证
export function sendSms(data) {
	return request({
		url: '/portal/sendSms',
		method: 'post',
		data: data
	})
}

//手机号验证
export function getSysConfig(key) {
	return request({
		url: '/portal/getSysConfig',
		method: 'get',
		params: {"key":key},
	})
}

