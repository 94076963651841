import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  name: 'Menu',
  props: {
    msg: String
  },
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const path = route.path == '/' ? '/index' : route.path; // const activeIndex = ref(path.indexOf('aboutUs')>-1?path:('/'+path.split('/')[1]))

    const handleSelect = v => {
      router.push({
        path: v
      });
    };

    onMounted(() => {
      handleSelect(path);
    });
    return {
      handleSelect
    };
  }

};