import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
  name: 'App',

  setup() {
    window.document.title = "爸妈在线-专业心身健康服务平台";
    const store = useStore();

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
      } else {
        ElMessage.error("该浏览器不支持定位功能！");
        store.dispatch("setLocationCity", '全部');
      }
    };

    const showPosition = position => {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      const pointBak = new B_Map.Point(lng, lat);
      const geo = new B_Map.Geocoder();
      geo.getLocation(pointBak, res => {
        const addComp = res.addressComponents;
        const cityName = addComp.city.replace('市', '');
        store.dispatch("setLocation", pointBak);
        store.dispatch("setLocationCity", cityName);
      }); // const convertor = new BMap.Convertor();
      // convertor.translate([pointBak], 1, 5, function (resPoint) {
      // 	if (resPoint && resPoint.points && resPoint.points.length > 0) {
      // 		lng = resPoint.points[0].lng;
      // 		lat = resPoint.points[0].lat;
      // 	}
      // 	const point = new BMap.Point(lng, lat);
      // 	const geo = new BMap.Geocoder();
      // 	geo.getLocation(point, (res) => {
      // 		const addComp = res.addressComponents;
      //     const cityName = addComp.city.replace('市', '');
      // 		store.dispatch("setLocation", point);
      // 		store.dispatch("setLocationCity", cityName);
      // 	});
      // });
    };

    const showError = error => {
      store.dispatch("setLocationCity", '全部'); // switch (error.code) {
      // 	case error.PERMISSION_DENIED:
      // 		ElMessage.error("用户拒绝对获取地理位置的请求。");
      // 		break;
      // 	case error.POSITION_UNAVAILABLE:
      // 		ElMessage.error("位置信息是不可用的。");
      // 		break;
      // 	case error.TIMEOUT:
      // 		ElMessage.error("请求用户地理位置超时。");
      // 		break;
      // 	case error.UNKNOWN_ERROR:
      // 		ElMessage.error("未知错误。");
      // 		break;
      // }
    };

    onMounted(() => {
      getLocation();
      store.dispatch('getContentTop');
      store.dispatch('getContentSecond');
      store.dispatch('getCounselorLevel');
      store.dispatch('getConsultType');
      store.dispatch('getCompanyInfo');
    });
  }

};