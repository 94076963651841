import userImg from '@/assets/images/ze-manager-o@1x.png';
import homeImg from '@/assets/images/ze-home-o@1x.png';
import { reactive } from 'vue';
export default {
  name: 'Detail',
  props: {
    contentDetail: Object,
    visible: Boolean
  },

  setup(props, ctx) {
    const state = reactive({
      imgUser: userImg,
      imgHome: homeImg
    });

    const close = () => {
      ctx.emit('close');
    };

    return {
      state,
      close
    };
  }

};